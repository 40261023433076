var exports = {};

/***
 * Convert string to hex color.
 *
 * @param {String} str Text to hash and convert to hex.
 * @returns {String}
 * @api public
 */
exports = function hex(str) {
  for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash));

  var color = Math.floor(Math.abs(Math.sin(hash) * 10000 % 1 * 16777216)).toString(16);
  return "#" + Array(6 - color.length + 1).join("0") + color;
};

export default exports;